import React from "react";

import PropTypes from "prop-types";

import ContentContainer from "../ContentContainer/ContentContainer";

const TextHeader = ({ children, title, classNameTitle }) => (
  <ContentContainer>
    <h2 className={`h1 mb-42 mt-72 ${classNameTitle}`}>{title}</h2>
    <div className="grid grid-cols-10 mb-90">
      <div className="col-span-10 lg:col-start-4 lg:col-span-7">
        {children}
      </div>
    </div>
  </ContentContainer>
);

TextHeader.propTypes = {
  children: PropTypes.node.isRequired,
  classNameTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

TextHeader.defaultProps = {
  classNameTitle: "",
};

export default TextHeader;
