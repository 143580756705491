import React, { Fragment, useRef } from "react";

import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ChevronDownIcon24, ChevronUpIcon24 } from "../../icons";

const InputField = (props) => {
  const {
    color,
    placeholder,
    label,
    message,
    type,
    id,
    name,
    preComponent,
    dropdownItems,
    ...extendedProps
  } = props;

  const inputRef = useRef(null);
  const styles = {
    black: {
      input: "border-black focus:border-black placeholder:text-dark-grey",
      label: "text-black",
      message: "text-dark-grey",
    },
    white: {
      input: "border-white focus:border-white placeholder:text-grey",
      label: "text-white",
      message: "text-grey",
    },
  };

  return (
    <div>
      {label && (
        <label htmlFor={name} className={classNames("block text-body2 mb-9", styles[color].label)}>
          {label}
        </label>
      )}
      <div className="flex relative">
        {preComponent && (
          <span
            className={
              classNames(
                "inline-flex items-center w-54 content-center justify-center border-1.5 border-r-0",
                styles[color].input,
              )
            }
          >
            {preComponent}
          </span>
        )}
        <input
          ref={inputRef}
          type={type}
          name={name}
          id={id}
          className={
            classNames(
              "text-body2 block w-full min-w-0 flex-1 border-1.5 px-18 py-14 focus:ring-0 focus:outline-0 placeholder:text-body2",
              styles[color].input,
              { "border-l-0": preComponent },
              { "border-r-0": React.Children.toArray(dropdownItems).length > 0 },
            )
          }
          placeholder={placeholder}
          {...extendedProps}
        />
        {React.Children.toArray(dropdownItems).length > 0 && (
          <Menu
            as="div"
            className="bg-white inline-flex items-center w-54 content-center justify-center border-1.5 border-l-0"
          >
            {({ open }) => (
              <>
                <div>
                  <Menu.Button>
                    {open
                      ? <ChevronUpIcon24 aria-hidden="true" />
                      : <ChevronDownIcon24 aria-hidden="true" />}
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute bg-white right-0 w-full top-45 z-10 pt-21 pb-24 pl-21 pr-30 border-1.5">
                    <div className="grid gap-y-18">
                      {React.Children.toArray(dropdownItems).map((item) => (
                        <Menu.Item key={item}>
                          <div
                            role="presentation"
                            className="text-body2 cursor-pointer"
                            onClick={(event) => {
                              if (inputRef?.current && typeof event.currentTarget.innerText === "string") {
                                inputRef.current.value = event.currentTarget.innerText;
                              }
                            }}
                          >
                            {item}
                          </div>
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        )}
      </div>
      {message && (
        <div className={classNames("text-small mt-9", styles[color].message)}>
          {message}
        </div>
      )}
    </div>
  );
};

InputField.propTypes = {
  color: PropTypes.oneOf(["black", "white"]),
  dropdownItems: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  preComponent: PropTypes.node,
  type: PropTypes.string,
};
InputField.defaultProps = {
  color: "black",
  dropdownItems: null,
  label: "",
  message: "",
  placeholder: "",
  preComponent: null,
  type: "text",
};
export default InputField;
