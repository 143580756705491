import React from "react";

import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import GetInternalLinkContext from "../utils/GetInternalLinkContext";

const ExhibitionCard = ({ data, className }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const lang = language === "de-DE" ? "" : `/${language}`;
  const link = GetInternalLinkContext({ id: data.id, slug: `${lang}/${t("ausstellungen").toLowerCase()}/${data.slug?.current?.replace(";", "-")}` });

  return (
    <div className={className}>
      <a href={link.path} title={link.title}>
        {data.images?.images?.length > 0 && data.images?.images[0]?.asset && (
          <SanityImage
            {...data.images?.images[0]}
            width={275}
            className="w-full"
          />
        )}
        <div className="text-body2 mb-3 mt-18">{t(data.type?.type)}</div>
        <h5>{data.title}</h5>
      </a>
    </div>

  );
};

export const query = graphql`
  fragment ExhibitionCard on SanityExhibition {
    id
    images {
      images {
          ...ImageWithPreview
          alt
      }
    }
    room
    slug {
      current
    }
    startdate
    title
    type {
      type 
    }  
    topic
    enddate
    collection {
      id
      title
    }
  }
`;

ExhibitionCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

ExhibitionCard.defaultProps = {
  className: "",
};

export default ExhibitionCard;
