import React, { useEffect, useState } from "react";

import { useLocation } from "@reach/router";
import BlockContent from "@sanity/block-content-to-react";
import classNames from "classnames";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import scrollTo from "gatsby-plugin-smoothscroll";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import ContentContainer from "../../components/ContentContainer/ContentContainer";
import InputField from "../../components/InputField/InputField";
import Masonry from "../../components/Masonry/Masonry";
import Section from "../../components/Section/Section";
import SidebarFilters from "../../components/SidebarFilters/SidebarFilters";
import TabNavigation from "../../components/TabNavigation/TabNavigation";
import TextHeader from "../../components/TextHeader/TextHeader";
import {
  addFilter,
  addReviewFilter,
  removeFilter,
  removeReviewFilter,
  setSearchTerm,
} from "../../features/ExhibitionFilterSlice";
import useMediaQuery from "../../hooks/useMediaQuery";
import { SearchIcon24 } from "../../icons";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ExhibitionCard from "../Exhibition/ExhibitionCard";
import ImageSlider from "../ImageSlider/ImageSlider";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const ExhibitionOverviewPage = ({
  data: {
    page,
    exhibitions,
  },
}) => {
  const {
    searchTerm,
    filters,
    filtersReview,
  } = useSelector((state) => state.exhibitionFilter);
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(0);
  const [currentAnchor, setCurrentAnchor] = useState();

  useEffect(() => {
    const hash = location.hash.substring(1);
    const i = hash && hash < 3 ? location.hash.substring(1) : 0;
    setActiveItem(i);
  }, [location]);

  useEffect(() => {
    if (location.hash) {
      scrollTo("#tabs");

      // TODO: not loaded images result in wrong scroll position.
      if (currentAnchor !== location.hash) {
        setTimeout(() => {
          scrollTo("#tabs");
        }, 500);
      }
    }
  }, []);

  const { t } = useTranslation();
  const lang = page.i18nLang || "de-DE";

  const filterCheckboxes = (item, filter) => {
    for (const [key, value] of Object.entries(filter)) {
      if (value.length > 0
        && !value.includes(item[key])
        && !value.includes(new Date(item[key]).toLocaleDateString(lang, { year: "numeric" }))) {
        return false;
      }
    }

    return true;
  };

  const filterActualCheckboxes = (item) => filterCheckboxes(item, filters);

  const filterReviewCheckboxes = (item) => filterCheckboxes(item, filtersReview);

  const filterPast = (item) => (
    new Date(new Date().toDateString()) >= new Date(new Date(item.enddate).toDateString()))
    && item.enddate;

  const filterActual = (item) => (
    new Date(new Date().toDateString()) <= new Date(new Date(item.enddate).toDateString())
    && new Date(new Date().toDateString()) >= new Date(new Date(item.startdate).toDateString()));

  const filterFuture = (item) => (
    new Date(new Date().toDateString()) <= new Date(new Date(item.startdate).toDateString()))
    || !item.enddate;

  const filterSearchterm = (item) => (
    searchTerm === ""
    || item.title?.toLowerCase().includes(searchTerm?.toLowerCase()));

  const filterDefinition = [
    {
      id: "type",
      name: "Jahre",
      options: [
        { href: "/sammlung/mehr-zur-grafik", label: "Was sind Dauerausstellungen?", value: "2023" },
        { href: "/sammlung/mehr-zur-grafik", label: "was sind Wechselausstellung?", value: "2023" },
      ],
    },
  ];

  const years = [];
  exhibitions.nodes?.map((exhibition) => {
    const year = new Date(exhibition.startdate).toLocaleDateString(lang, { year: "numeric" });

    if (exhibition.enddate
      // eslint-disable-next-line max-len
      && new Date(new Date().toDateString()) >= new Date(new Date(exhibition.enddate).toDateString())) {
      years[year] = { label: year, value: year };
    }
  });

  const filterDefinitionReview = [
    {
      id: "startdate",
      name: t("year"),
      options: years.sort().reverse(),
    },
  ];

  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      {!page._rawQuickInfoText && (
        <ContentContainer>
          <h1>{page.title}</h1>
        </ContentContainer>
      )}
      {page._rawQuickInfoText && (
        <TextHeader title={page.title}>
          <BlockContent
            blocks={page._rawQuickInfoText}
          />
        </TextHeader>
      )}
      <div className="-mt-8 lg:-mt-18">
        <ImageSlider data={page.slider} />
      </div>
      <TabNavigation
        idName="tabs"
        initalItem={activeItem}
        items={[
          {
            content: (
              <Masonry breakpointCols={isMobile ? 1 : 3}>
                {exhibitions.nodes?.filter(filterActualCheckboxes)
                  .filter(filterSearchterm)
                  .filter(filterActual)
                  .map(
                    (exhibition, index) => (
                      <ExhibitionCard data={exhibition} className={classNames({ "mt-60": index === 0 || index === 2 })} />
                    ),
                  )}
              </Masonry>
            ),
            tab: t("current"),
          },
          {
            content: (
              <Masonry breakpointCols={isMobile ? 1 : 3}>
                {exhibitions.nodes?.filter(filterActualCheckboxes)
                  .filter(filterSearchterm)
                  .filter(filterFuture)
                  .map(
                    (exhibition, index) => (
                      <ExhibitionCard data={exhibition} className={classNames({ "mt-60": index === 0 || index === 2 })} />
                    ),
                  )}
              </Masonry>
            ),
            tab: t("preview"),
          },
          {
            content: (
              <SidebarFilters
                className="my-36 lg:my-90"
                filters={filterDefinitionReview}
                filterState={filtersReview}
                onChange={(event) => {
                  event.target.checked
                    ? dispatch(addFilter(
                      {
                        section: event.target.name.slice(0, -2),
                        value: event.target.value,
                      },
                    ))
                    : dispatch(removeFilter(
                      {
                        section: event.target.name.slice(0, -2),
                        value: event.target.value,
                      },
                    ));
                }}

              >
                <Masonry breakpointCols={isMobile ? 1 : 3}>
                  {exhibitions.nodes?.filter(filterActualCheckboxes)
                    .filter(filterPast)
                    .map(
                      (exhibition, index) => (
                        <ExhibitionCard data={exhibition} className={classNames({ "mt-60": index === 0 || index === 2 })} />
                      ),
                    )}
                </Masonry>
              </SidebarFilters>
            ),
            tab: t("review"),
          },
        ]}
      />

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!, $graphqlLocale: [String]) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityExhibitionOverview(id: {eq: $id}) {
      i18n_lang
      id
      title
      slider {
        ...ImageSlider
      }
      _rawQuickInfoText
      exhibition {
        elements {
          text
          headline
          image {
            ...ImageWithPreview
            alt
          }
        }
      }
      Metadata {
        ...MetaData
      }
    }
    exhibitions: allSanityExhibition(
      filter: {images: {images: {elemMatch: {_key: {ne: null}}}}, i18n_lang: {in: $graphqlLocale} }
    ) {
      nodes {
        id
        i18n_lang
        images {
          images {
              ...ImageWithPreview
              alt
          }
        }
        room
        slug {
          current
        }
        startdate
        title
        type {
          type
        }
        topic
        enddate
        collection {
          id
          title
        }
      }
    }
  }
`;

ExhibitionOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ExhibitionOverviewPage;
